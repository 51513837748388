export class DieselBillModel {
    public id:number;
    public slipNo: string;
    public dates: string;
    public vehicleModelId: number;
    public vendorModelId: number;
    public hpAgenciesModelId: number;
    public dieselLtr: number;
    public dieselRate: number;
    public dieselAmount: number;
    public remarks: string;
}
