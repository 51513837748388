export class FilterMaintenance {
    public startDate: string;
    public endDate: string;
    public vendorId: number;
    public vehicleId: number;
    public billNo: number;
    public particular: string;
    public type: string;
    public search_con: string;

    clear(){
       this.startDate='';
       this.endDate='';
       this.vendorId=0;
       this.vehicleId=0;
       this.billNo=0;
       this.particular='';
       this.type='';
       this.search_con='';
    }
}
