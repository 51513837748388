export class HpclDealer {
    
      public id: number;
        public name: string;
        public address: string;
        public contactNo: string;
        public emailId: string;
        public panNo: string;
        public gstNo: string;
        public state: string;
        public rtd: string;
        public driverTripAllowance: string;
        public company_name: string
      
}
