export class FilterHpcltripReport {
    public startDate: String;
    public endDate: String;
    public vehicleId: number;
    public employeeId: number;

    clear(){
        this.startDate ='';
        this.endDate='';
        this.vehicleId=0;
        this.employeeId=0;
    }
}
