import { ResponseModel } from './../../common/response-model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Globals } from 'src/app/common/global';
import { PaymentModel } from 'src/app/local/local-payment/payment-model';
import { ClientPaymentModel } from 'src/app/local/local-payment/client-payment-model';

@Injectable({
  providedIn: 'root'
})
export class InvoicePaymentService {


  constructor(private httpClient: HttpClient, private global : Globals) { }

  create(trip:any) {
    return this.httpClient.post<ResponseModel>(this.global.api_Url + this.global.client_payment + '/add', trip)
  }
  getAll()  {
    return this.httpClient.get<ResponseModel>(this.global.api_Url + this.global.client_payment + '')
    // .pipe(
    //   catchError(this.errorHandler)


    //)
  }

  getclientStatement(clientId,startDate,endDate)  {
    // return this.httpClient.get(this.global.api_Url + this.global.client_payment +'/client-statement', data)
    const url = this.global.api_Url + this.global.client_payment +'/client-statement' +'?clientId='+clientId+'&startDate='+startDate+'&endDate='+endDate;
      return this.httpClient.get<ResponseModel>(url);
  }

  getAll_dues()  {
    return this.httpClient.get<ResponseModel>(this.global.api_Url + this.global.client_payment + '/client-dues')
    // .pipe(
    //   catchError(this.errorHandler)
    //)
  }


  

  delete(id:number){
    //  debugger
      return this.httpClient.delete<ResponseModel>(this.global.api_Url + this.global.client_payment + '/' + id)
      // .pipe(
      //   catchError(this.errorHandler)
      // )
    }

    update(id, data) {
      return this.httpClient.put<ResponseModel>(this.global.api_Url + this.global.client_payment + '/' + id, data)
    }

    getbyid(id:any)  {
      return this.httpClient.get<ResponseModel>(this.global.api_Url + this.global.client_payment + '/'+id)
      // .pipe(
      //   catchError(this.errorHandler)
      //)
    }

    paymentList(pageNo,pageSize,startDate,endDate){
      const url = this.global.api_Url+ this.global.payment_report +'?pageNo='+pageNo+'&pageSize='+pageSize+'&startDate='+startDate+'&endDate='+endDate;
      return this.httpClient.get<ResponseModel>(url);
    }

    getDuesByClientId(id:any)  {
      return this.httpClient.get<ResponseModel>(this.global.api_Url + this.global.client_dues_by_id + '?clientId='+id)
    }
    
    

  errorHandler(error:any) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }
}
