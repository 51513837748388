import { ResponseModel } from './../../common/response-model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { IocldealerModel } from 'src/app/hpcl/dealer/hpcl-dealer';
import { IocldealerModel } from 'src/app/iocl/iocl-dealer/iocldealer-model';
import { Globals } from 'src/app/common/global';

@Injectable({
  providedIn: 'root'
})
export class IoclDealerService {

  // hpcldealr=IocldealerModel;
  constructor(private httpClient: HttpClient, private global : Globals) { }

  create(dealer:any) {
    return this.httpClient.post<ResponseModel>(this.global.api_Url + this.global.ioclDealer + '/add', dealer)
  }
  getAll()  {
    return this.httpClient.get<ResponseModel>(this.global.api_Url + this.global.ioclDealer + '/')
    // .pipe(
    //   catchError(this.errorHandler)
    //)
  }

  
  
  

  delete(id:number){
    //  debugger
      return this.httpClient.delete<ResponseModel>(this.global.api_Url + this.global.ioclDealer + '/' + id)
      // .pipe(
      //   catchError(this.errorHandler)
      // )
    }

    update(id, data) {
      return this.httpClient.put<ResponseModel>(this.global.api_Url + this.global.ioclDealer + '/' + id, data)
    }

    getbyid(id:any)  {
      return this.httpClient.get<ResponseModel>(this.global.api_Url + this.global.ioclDealer + '/'+id)
      // .pipe(
      //   catchError(this.errorHandler)
      //)
    }


  errorHandler(error:any) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }
}
